.rounded-textfield .MuiOutlinedInput-root {
    border-radius: 30px;
}

//Table
.tableHeadLabel {
    color: #ffff !important;
}

.tableHeadLabel:hover {
    color: rgb(65, 245, 101) !important;
}

.tableHead {
    background-color: #1660b6;
}

.css-1cfz0qq.Mui-active {
    color: #ffc069 !important;
}
.css-1cfz0qq.Mui-active .MuiTableSortLabel-icon {
    color: #ffc069 !important;
}