.container{
    position: relative;
    width: 400px;
    height: 350px;
    zoom: 0.9;
}


.curve1 {
    position: absolute;
    width: 80px;
    height: 200px;
    background: url('../../../../../assets/images/s1-0.gif') no-repeat;
    background-size: 100%;
    top: 70px;
    left: 65px;
}
//=========
.curve2 {
    position: absolute;
    width: 80px;
    height: 200px;
    background: url('../../../../../assets/images/s3-0.gif') no-repeat;
    background-size: 100%;
    top:70px;
    left: 255px;
}
.curve2Next {
    position: absolute;
    width: 80px;
    height: 200px;
    background: url('../../../../../assets/images/s3-1.gif') no-repeat;
    background-size: 100%;
    top:70px;
    left: 255px;
}
// .curve2Prev {
//     position: absolute;
//     width: 80px;
//     height: 200px;
//     background: url('../../../../../assets/images/s3-1.gif') no-repeat;
//     background-size: 100%;
//     left: 215px;
//     top: 22px;
//     transform: scaleY(-1) rotate(70deg);
// }
//=============
.curve3 {
    position: absolute;
    width: 160px;
    height: 200px;
    background: url('../../../../../assets/images/s6-0.gif') no-repeat;
    background-size: 100%;
    top: 280px;
    left: 120px;
}

//====================
.straightLine1 {
    position: absolute;
    width: 10px;
    height: 60px;
    background: url('../../../../../assets/images/s2-0.gif') no-repeat;
    background-size: 100%;
    left: 195px;
    top: 118px;
}
.straightLine1Next {
    position: absolute;
    width: 12px;
    height: 60px;
    background: url('../../../../../assets/images/s2-1.gif') no-repeat;
    background-size: 100%;
    left: 193px;
    top: 120px;
}
.straightLine2 {
    position: absolute;
    width:30px;
    height: 60px;
    background: url('../../../../../assets/images/s4-0.gif') no-repeat;
    background-size: 100%;
    top: 205px;
    left: 118px;
}
.straightLine3 {
    position: absolute;
    width:30px;
    height: 60px;
    background: url('../../../../../assets/images/s5-0.gif') no-repeat;
    background-size: 100%;
    top: 205px;
    right: 118px;
}
//==============

.home{
    position: absolute;
    background: url('../../../../../assets/images/home-load-power.png') no-repeat;
    background-size: 100%;
    width: 100px;
    height: 140px;
    top:155px;
    left: 150px;
}
.homepv{
    position: absolute;
    background: url('../../../../../assets/images/home-pv.png') no-repeat;
    background-size: 100%;
    width: 100px;
    height: 140px;
    left: 150px;
}

.feed-in{
    position: absolute;
    background: url('../../../../../assets/images/home-grid.png') no-repeat;
    background-size: 100%;
    width: 100px;
    height: 140px;
    top: 195px;
    left: 20px;
}

.battery{
    position: absolute;
    background: url('../../../../../assets/images/home-battery-power0.png') no-repeat;
    background-size: 100%;
    width: 100px;
    height: 140px;
    top:195px;
    // left: 270px;
    right: 20px;
}

.content{
    position: absolute;
    top:62px;
    margin-left: 25px;
    width: 53px;
    text-align: center;
}


.CarbonImpactMeasures{
    position: absolute;
    bottom: -50px;
    width: 420px;
    left: -10px;
    height: 80px;
    display: flex;
}

img{
    margin: auto;
}