.custom-tooltip {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .tooltip-label {
    font-weight: bold;
    color: black;
  }
  